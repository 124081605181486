var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f73cead55426ade168fb25cda5f21c2156f99266"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const SENTRY_TRACE_SAMPLE_RATE = Number(
  process.env.SENTRY_TRACE_SAMPLE_RATE ||
    process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE,
);

const SENTRY_REPLAYS_SESSION_SAMPLE_RATE = Number(
  process.env.SENTRY_REPLAYS_SESSION_SAMPLE_RATE ||
    process.env.NEXT_PUBLIC_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
);

const SENTRY_ON_ERROR_SESSION_SAMPLE_RATE = Number(
  process.env.SENTRY_ON_ERROR_SESSION_SAMPLE_RATE ||
    process.env.NEXT_PUBLIC_SENTRY_ON_ERROR_SESSION_SAMPLE_RATE,
);

Sentry.init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: SENTRY_REPLAYS_SESSION_SAMPLE_RATE,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: SENTRY_ON_ERROR_SESSION_SAMPLE_RATE,
});
